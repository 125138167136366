import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import usePatchBoardQna from 'hooks/service/mutations/usePatchBoardQna';
import useGetMinHeight from 'hooks/useGetMinHeight';
import useParamsId from 'hooks/useParamsId';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import { maximumLength } from 'utils/validate';
import { object } from 'yup';

import { QNA_DETAIL_TEXT } from '../constants';
import { CommentFormType, QnaCommentType } from '../types';

type Props = {
  comment: QnaCommentType;
};

const yupSchema = object().shape({
  contents: maximumLength(255),
});

const CommentEditPopup = ({ comment }: Props) => {
  const questionId = useParamsId();
  const { mutate: editCommentMutate, isPending } = usePatchBoardQna(questionId, comment.id);
  const { setPopup } = usePopup();
  const { setToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    setFocus,
  } = useForm<CommentFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      contents: comment.contents,
    },
  });

  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const saveEdited = useCallback(
    (values: CommentFormType) => {
      editCommentMutate(values, {
        onSuccess: () => setToast({ type: 'success', message: QNA_DETAIL_TEXT.editSuccess, bottom: 96 }),
        onError: () =>
          setErrorBackStep({
            to: `/more-details/qna/${questionId}`,
            options: { replace: true, state: { permissionDenied: true } },
          }),
        onSettled: () => setPopup(null),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editCommentMutate],
  );

  useEffect(() => {
    setFocus('contents');
  }, [setFocus]);
  const fullHeight = useGetMinHeight();

  return (
    <MainLayout
      header={{
        title: QNA_DETAIL_TEXT.editTitle,
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <EditForm
        onSubmit={handleSubmit(saveEdited)}
        footerButton={{ text: QNA_DETAIL_TEXT.editButton, disabled: !isDirty || isPending }}
        minHeight={fullHeight}>
        <Textarea {...register('contents')} height={240} />
      </EditForm>
    </MainLayout>
  );
};

export default CommentEditPopup;

const EditForm = styled(Form)<{ minHeight: number }>`
  height: 100%;
  padding: 16px 20px;
  ${theme.flex('column', '', 'space-between')}
  min-height: ${({ minHeight }) => minHeight}px;
`;
