import { AxiosError } from 'axios';
import usePatchLectureGroupBulk, { LectureGroupBulkParams } from 'hooks/service/mutations/usePatchLectureGroupBulk';
import { LectureValidationError } from 'hooks/service/mutations/usePatchLecturePrivate';
import { LectureCourseResponse } from 'hooks/service/queries/useGetLectureCourse';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import LectureOverlap from 'pages/Booking/components/AfterRequestBooking/LectureOverlap';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { formatBookingGroupParams } from 'pages/Booking/utils';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

import { BookingGroupCreateFormType } from '../../types';
import CommonGroupForm from '../components/CommonGroupForm';

type Props = {
  currentLecture: LectureDetailResponse;
  course: LectureCourseResponse;
};

const GroupUpdateAllForm = ({ currentLecture, course }: Props) => {
  const {
    reset,
    getValues,
    formState: { defaultValues },
  } = useFormContext<BookingGroupCreateFormType>();
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const { mutate: groupBulkUpdateMutate, isPending } = usePatchLectureGroupBulk(currentLecture);

  const resetFormAfterError = useCallback(() => {
    reset(getValues(), { keepDefaultValues: true });
  }, [getValues, reset]);

  const updateAllGroup = useCallback(
    (updateAllParams: Omit<LectureGroupBulkParams, 'start' | 'start_date'>) => {
      const params = {
        ...updateAllParams,
        start: currentLecture.start_on,
        start_date: course.start_date,
      };
      groupBulkUpdateMutate(params, {
        onSuccess: () => {
          setPopup(null);
          navigate(-1);
          setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
        },
        onError: (error: AxiosError<LectureValidationError>) => {
          setPopup(null);
          if (!error.response?.data) return;
          const errorData = error.response.data;
          if (errorData.overlap) {
            setPopup(
              <LectureOverlap
                fails={errorData.overlap}
                onSubmit={() => updateAllGroup({ ...params, is_force: true })}
                goBack={resetFormAfterError}
              />,
            );
            return false;
          }
        },
      });
    },
    [course.start_date, currentLecture.start_on, groupBulkUpdateMutate, navigate, resetFormAfterError, setPopup, setToast],
  );

  const submit = useCallback(
    (values: BookingGroupCreateFormType) => {
      const { minTrainee, ...rest } = values;
      const formValues = {
        ...rest,
        minTrainee,
        policy: {
          ...values.policy,
          autocloseDeadline: minTrainee ? values.policy.autocloseDeadline : defaultValues?.policy?.autocloseDeadline ?? 0,
        },
      };
      const { start_date, ...params } = formatBookingGroupParams(formValues);
      updateAllGroup({ ...params });
    },
    [defaultValues?.policy?.autocloseDeadline, updateAllGroup],
  );

  useEffect(() => {
    if (isPending) {
      setPopup(<ProcessingPopupContents title={BOOKING_COMMON_FORM_TEXT.loadingTitle} />);
    }
  }, [isPending, setPopup]);

  return (
    <CommonGroupForm pageMode="updateAll" currentLecture={currentLecture} onSubmit={submit}>
      <BookingStartDate />
      <BookingEndDate />
      <BookingRangeTime pageMode="updateAll" />
    </CommonGroupForm>
  );
};

export default GroupUpdateAllForm;
