import { queryClient } from 'api/queryClient';
import useGetBookingCount from 'hooks/service/queries/useGetBookingCount';
import useErrorDialog from 'hooks/useErrorDialog';
import useGetMinHeight from 'hooks/useGetMinHeight';
import { isArray } from 'lodash';
import { StyledBookingForm, StyledBookingListTitleTypography } from 'pages/Booking/BookingMember/BookingForm';
import { TICKET_INVALID_KEYS } from 'pages/Booking/constants';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { AllPageModeType } from 'pages/Booking/types';
import { formatBookingCountParams, getCurrentSelectedUserTicketIds } from 'pages/Booking/utils/private/booking';
import { containsExactElements } from 'pages/Booking/utils/selectMembers';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import ValidErrorDrawer from '../ValidErrorDrawer';
import List from './List';
import ReserveUserTickets from './ReserveUserTickets';
import SaveButton from './SaveButton';

type Props = {
  pageMode: AllPageModeType;
};

const SelectMemberForm = ({ pageMode }: Props) => {
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const fullHeight = useGetMinHeight();
  const { getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const navigate = useNavigate();

  const { originUserTicketIds, selectedUserTickets } = getValues();
  const [isRequestCountValid, setIsRequestCountValid] = useState(false);
  const [isOpenValidationDrawer, setIsOpenValidationDrawer] = useState(false);

  const { data: bookingCountValidation, error } = useGetBookingCount(isRequestCountValid, formatBookingCountParams(getValues()));

  const removeQuery = () => {
    setIsRequestCountValid(false);
    queryClient.removeQueries({ queryKey: ['booking/count'] });
  };

  const submit = () => {
    removeQuery();
    // 하단 완료 버튼 클릭하면 기존 선택된 아이디와 다른 아이디일 때 검증 요청
    const userTicketIds = getCurrentSelectedUserTicketIds(getValues('selectedUserTickets'));
    if (!containsExactElements({ userTicketIds, originUserTicketIds })) {
      setIsRequestCountValid(true);
    } else {
      navigate(-1);
    }
  };

  const confirm = () => {
    removeQuery();
    navigate(-1);
  };

  useEffect(() => {
    if (!bookingCountValidation) return;
    setIsRequestCountValid(false);

    /** 초과 예약 검증 걸린 것이 없어서 바로 예약하면 될 때 (반환값으로 빈배열이 옴) */
    if (isArray(bookingCountValidation)) {
      return navigate(-1);
    }

    /** 초과 예약 걸렸을 때 확인 dialog 노출 */
    if (bookingCountValidation) {
      setIsOpenValidationDrawer(true);
    }
  }, [bookingCountValidation, navigate]);

  const errorDialog = useErrorDialog();

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey: ['user-ticket', 'private'] });
    setValue('selectedUserTickets', []);
    setValue('prevSelectedUserTickets', []);
    setValue('title', '');
  };

  useEffect(() => {
    if (!error || !error.response) return;
    const errorData = error.response.data;

    removeQuery();

    if (TICKET_INVALID_KEYS.includes(errorData.code)) {
      const message = `${errorData.message}\n페이지를 새로고침 해주세요.`;
      errorDialog.open(message, refresh, '새로고침');
    } else if (errorData.code === 10619) {
      // 같은 회원 중복 수강권 에러
      errorDialog.open(errorData.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const savePrevUserTickets = () => {
    setValue('prevSelectedUserTickets', [...selectedUserTickets]);
  };

  useEffect(() => {
    savePrevUserTickets();
    return () => {
      removeQuery();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledBookingForm isKeyboardShow={isKeyboardShow} minHeight={fullHeight}>
        <ReserveUserTickets />
        <StyledBookingListTitleTypography size={13} weight={500} textColor="gray2">
          회원목록
        </StyledBookingListTitleTypography>
        <ApiBoundary>
          <List />
        </ApiBoundary>
        <SaveButton onClick={submit} />
      </StyledBookingForm>

      {bookingCountValidation && (
        <ValidErrorDrawer
          bookingCountValidation={bookingCountValidation.limit}
          isOpen={isOpenValidationDrawer}
          onClose={() => {
            setIsOpenValidationDrawer(false);
          }}
          onClick={confirm}
        />
      )}
    </>
  );
};

export default SelectMemberForm;
