import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import FilterTabs from 'components/Tabs/FilterTabs';
import useQueryString from 'hooks/useQueryString';

import { FAQ_CATEGORIES } from '../constants';
import { FaqCategoryType } from '../type';

const Categories = () => {
  const { getSearchParams, getAllSearchParams, setSearchParams } = useQueryString();

  const category = getSearchParams('category') ?? '';
  const selectedCategory = FAQ_CATEGORIES.filter(c => c.value === category)[0] ?? FAQ_CATEGORIES[0];

  const applyCategory = (newCategory: FaqCategoryType) => {
    setSearchParams({ ...getAllSearchParams(), category: newCategory.value }, { replace: true });
  };
  return (
    <TabGroups
      scrollable
      gap={4}
      data={FAQ_CATEGORIES}
      value={selectedCategory.value}
      selectedColor="point"
      padding={{ left: 16, right: 16 }}
      onChange={applyCategory}
    />
  );
};

export default Categories;

const TabGroups = styled(FilterTabs)`
  margin-top: 14px;
  &:not(.selected) {
    background-color: ${theme.color.gray7};
    border: none;
    color: ${theme.color.gray1};
  }
  &:last-of-type {
    margin-right: 20px;
  }
`;
