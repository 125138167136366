import styled from '@emotion/styled';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import usePatchLecturePrivate, {
  LecturePrivatePatchParams,
  LectureValidationError,
} from 'hooks/service/mutations/usePatchLecturePrivate';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useToast from 'hooks/useToast';
import BookingAvailableField from 'pages/Booking/components/AvailableTime/BookingAvailableField';
import CheckInAvailableField from 'pages/Booking/components/AvailableTime/CheckInAvailableField';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import OverlapDialog from 'pages/Booking/components/OverlapDialog';
import PastLectureUpdateDialog from 'pages/Booking/components/PastLectureUpdateDialog';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import { formatBookingPrivateUpdateParams } from 'pages/Booking/utils';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingPrivateUpdateFormType } from '../../types';
import StyledPrivateForm from '../components/StyledPrivateForm';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
};
const PrivateUpdateForm = ({ currentLecture }: Props) => {
  const { handleSubmit, getValues, reset } = useFormContext<BookingPrivateUpdateFormType>();

  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const [isOverlap, setIsOverlap] = useState(false);
  const [isPastLecture, setIsPastLecture] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: updatePrivateMutate } = usePatchLecturePrivate(currentLecture.id);

  const resetFormAfterError = useCallback(() => {
    reset(getValues(), { keepDefaultValues: true });
  }, [getValues, reset]);

  const updatePrivate = useCallback(
    (params: LecturePrivatePatchParams) => {
      updatePrivateMutate(params, {
        onSuccess: () => {
          navigate(-1);
          setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
        },
        onError: (error: AxiosError<LectureValidationError>) => {
          if (!error.response?.data) return;
          const errorData = error.response.data;
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            setIsOverlap(true);
            return false;
          }
        },
      });
    },
    [navigate, setToast, updatePrivateMutate],
  );

  // 1. 주/월간 초과해도 수정
  const clickPassLimit = useCallback(() => {
    const params = {
      ...formatBookingPrivateUpdateParams(getValues()),
      is_pass_limit: true,
    };
    updatePrivate(params);
    setBookingCountValidation(null);
  }, [getValues, updatePrivate]);

  // 2. 수업 수정 시에는 중복 수업도 등록 허용 (정책)
  const clickOverlapBooking = useCallback(() => {
    const params = {
      ...formatBookingPrivateUpdateParams(getValues()),
      is_pass_limit: true,
      is_force: true,
    };
    updatePrivate(params);
    setIsOverlap(false);
  }, [getValues, updatePrivate]);

  const submit = (values: BookingPrivateUpdateFormType) => {
    if (dayjs(currentLecture.start_on).isBefore()) {
      setIsPastLecture(true);
      return;
    }
    const params = formatBookingPrivateUpdateParams(values);
    updatePrivate(params);
  };

  return (
    <>
      <StyleForm onSubmit={handleSubmit(submit)} showEnterField={currentLecture.policy.is_enter}>
        <ClassTitle />
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingRangeDate isRange={false} />
        <BookingRangeTime pageMode="update" />
        <Room />
        <BookingAvailableField />
        {!!currentLecture.policy.is_enter && <CheckInAvailableField />}
        <SubmitButton />
      </StyleForm>

      {bookingCountValidation && (
        <LimitValidationDialog
          bookingCountValidation={bookingCountValidation}
          onClickBooking={clickPassLimit}
          onClose={() => {
            resetFormAfterError();
            setBookingCountValidation(null);
          }}
        />
      )}
      {isOverlap && (
        <OverlapDialog
          onClick={clickOverlapBooking}
          onClose={() => {
            resetFormAfterError();
            setIsOverlap(false);
          }}
        />
      )}
      {isPastLecture && <PastLectureUpdateDialog onClose={() => setIsPastLecture(false)} />}
    </>
  );
};

export default PrivateUpdateForm;

const StyleForm = styled(StyledPrivateForm)<{ showEnterField?: boolean }>`
  .booking-cancel-available-button {
    ${({ showEnterField }) => !showEnterField && `border: none;`}
  }
`;
