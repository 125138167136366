import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { staffListForScheduleFilter } from 'recoil/moreDetail/atoms';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import getImageUrl from 'utils/getImageUrl';

import { ScheduleBookingParamType } from '../type';
import ScheduleStaffDrawer from './ScheduleStaffDrawer';

const MultiStaffFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const staffs = useRecoilValue(staffListForScheduleFilter);
  const prevSelectedStaffs = selectedOption as ScheduleBookingParamType['staffs'];
  const [isOpen, setIsOpen] = useState(false);
  const [staffOptions, setStaffOptions] = useState<FilterOptionsType[]>(currentFilter.options);

  const isAllChecked = prevSelectedStaffs?.find(value => value.name === 'all');
  const label = useMemo(() => {
    if (isAllChecked || !prevSelectedStaffs?.length) {
      return '전체';
    } else {
      return prevSelectedStaffs.length === 1
        ? prevSelectedStaffs[0].name
        : `${prevSelectedStaffs[0].name} 외 ${prevSelectedStaffs.length - 1}명`;
    }
  }, [isAllChecked, prevSelectedStaffs]);

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const convertedOptions = staffs.map(staff => ({
      value: {
        id: staff.id,
        imgUrl: getImageUrl(staff.avatar),
        name: staff.name,
      },
      label: staff.name,
    }));

    setStaffOptions(convertedOptions);
  }, [staffs]);

  return (
    <Container>
      <Typography size={15} weight={700}>
        강사
      </Typography>
      <StaffSelectButton
        borderRadius={10}
        textColor="gray1"
        icon={<Icon name="arrowBottom" color="gray2" />}
        onClick={() => setIsOpen(true)}>
        {label}
      </StaffSelectButton>
      <ScheduleStaffDrawer
        selectedOption={selectedOption}
        isOpen={isOpen}
        staffOptions={staffOptions}
        onClose={onClose}
        onSave={changeOptions}
      />
    </Container>
  );
};

export default MultiStaffFilter;

const Container = styled.div`
  padding: 24px 20px;

  .select-drawer {
    margin-top: 16px;
  }
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;
