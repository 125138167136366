import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityScheduleManagementBooking, {
  ScheduleManagementBookingParams,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { memo, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { bookingParamsAtom, isEveryPermissionMoreDetailScheduleAtom, staffListForScheduleFilter } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { ScheduleStaff } from '../../type';
import { getScheduleGroupByStartOn, getStaffIds } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const BookingScheduleList = ({ currentTab }: Props) => {
  useScrollRestoration();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const staffs = useRecoilValue(staffListForScheduleFilter);
  const isEveryPermission = useRecoilValue(isEveryPermissionMoreDetailScheduleAtom);
  const [scheduleBooking, setBookingParams] = useRecoilState(bookingParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');

  const scheduleBookingParams: ScheduleManagementBookingParams = useMemo(
    () => ({
      staff_ids: isEveryPermission ? getStaffIds(scheduleBooking.staffs || []) : [currentStaffId],
      start_date: scheduleBooking.period?.start || filters.dateDash(),
      end_date: scheduleBooking.period?.end || filters.dateDash(),
      type: scheduleBooking.type,
      paginate_type: 'detail',
      status: scheduleBooking.status,
      order_by: scheduleBooking.sort ?? 'asc',
      search: searchKeyword,
    }),
    [
      isEveryPermission,
      scheduleBooking.staffs,
      scheduleBooking.period?.start,
      scheduleBooking.period?.end,
      scheduleBooking.type,
      scheduleBooking.status,
      scheduleBooking.sort,
      currentStaffId,
      searchKeyword,
    ],
  );

  const {
    data = { schedules: [], total: 0, groupBookingCount: 0, privateBookingCount: 0 },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfinityScheduleManagementBooking(scheduleBookingParams);

  const filteredSchedules = useMemo(() => {
    return getScheduleGroupByStartOn(data.schedules) as ScheduleManagementBookings[][];
  }, [data.schedules]);

  useEffect(() => {
    if (!isEveryPermission) {
      const currentStaff = staffs.find(staff => staff.id === currentStaffId);
      if (currentStaff) {
        const staffInfo = {
          id: currentStaff.id,
          imgUrl: getImageUrl(currentStaff.avatar),
          name: currentStaff.name,
        } as ScheduleStaff;

        setBookingParams(prev => {
          if (!prev.staffs?.some(s => s.id === staffInfo.id)) {
            return { ...prev, staffs: [staffInfo] };
          }
          return prev;
        });
      }
    }
  }, [currentStaffId, isEveryPermission, staffs]);

  useEffect(() => {
    const shouldRefetch =
      scheduleBooking.staffs ||
      scheduleBooking.period?.start ||
      scheduleBooking.period?.end ||
      scheduleBooking.type ||
      scheduleBooking.status ||
      scheduleBooking.sort ||
      searchKeyword;

    if (shouldRefetch) {
      refetch();
    }
  }, [
    scheduleBooking.staffs,
    scheduleBooking.period?.start,
    scheduleBooking.period?.end,
    scheduleBooking.type,
    scheduleBooking.status,
    scheduleBooking.sort,
    searchKeyword,
    refetch,
  ]);

  if (data.total === 0 || filteredSchedules.length === 0)
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={data.groupBookingCount} privateTotal={data.privateBookingCount} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.booking} />
      </>
    );

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={data.total} groupTotal={data.groupBookingCount} privateTotal={data.privateBookingCount} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
        <ContentSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ScheduleCard schedules={filteredSchedules} currentTab={currentTab} />
          </InfiniteScroll>
        </ContentSection>
      </PullToRefresh>
    </Container>
  );
};

export default memo(BookingScheduleList);

const Container = styled.div`
  & > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;
