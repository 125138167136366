import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const ReportLectureAccordionSkeleton = () => {
  return (
    <>
      <SkeletonWrapper>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
      </SkeletonWrapper>
      <Divider thin thinColor="gray6" />
      <SkeletonWrapper>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
        <div className="info-wrapper">
          <Skeleton height={22} width={55} />
          <Skeleton height={22} width={60} />
        </div>
      </SkeletonWrapper>
    </>
  );
};

export default ReportLectureAccordionSkeleton;

const SkeletonWrapper = styled.div`
  height: 182px;
  padding: 20px;

  .info-wrapper {
    ${theme.flex('', '', 'space-between')}
  }
  .info-wrapper:not(:last-of-type) {
    padding-bottom: 8px;
  }
`;
